import Legalese from '../../components/Legalese';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../../components/Header';

const Privacy = () => {
  return (
    <>
      <Header />
      <Row className="text-dark position-relative mt-5">
        <Col lg={1} />
        <Col lg={10}>
          <p>
            <strong>QuantumRed (QR Advisors, LLC) Privacy Policy</strong>
          </p>
          <p>Last Updated January 10, 2024</p>
          <p>
            <strong>INTRODUCTION</strong>
          </p>
          <p>
            QR Advisors, LLC. (<strong>“QR,”</strong> “we,” “our,” or “us”)
            offers digital tools, Software-as-a-Service and other digital
            resources (collectively the QR <strong>“Tools”</strong>) and
            consulting services (the <strong>“Services”</strong>) to Clients.
            Our Clients include nonprofits organizations, social organizations,
            private companies, advocacy groups, social movements, political
            campaigns and committees, their staff, their consultants
            (collectively, our <strong>“Clients”</strong>) and others delegated
            access to the Tools or Services by our Clients (the{' '}
            <strong>“Authorized Users”</strong>).
          </p>
          <p>
            This Privacy Policy is designed to assist you in understanding how
            we collect, use, share, and safeguard your information.
          </p>
          <p>
            <strong>
              Our Privacy Notice sets forth our policies and practices for
              handling the information we collect from you when you use our
              websites or interact with us as a user. The Privacy Notice is
              designed to ensure that visitors to the Tools understand our
              privacy practices when they visit or use the Site (together with
              interactions with our client support agents, our “Site”). Use of
              individual Tools linked to or provided by QR to our Clients (such
              as Benjamin Systems, DRE, or other technologies) are governed by
              their own Privacy Policies and Terms of Use.
            </strong>
          </p>
          <p>
            Please review this Privacy Policy periodically. It is subject to
            change, and amended versions will be published from time to time.
          </p>
          <p>
            <strong>Information we Collect</strong>
          </p>
          <p>
            We collect Personally Identifiable Information from you through your
            use of the Site and Services. “Personally Identifiable Information”
            is individually identifiable information about an individual
            consumer that we collect.
          </p>
          <p></p>
          <p>
            <strong>1. Information You Provide</strong>
          </p>
          <p>
            We may collect the following Personally Identifiable Information
            that you voluntarily provide to us in using our Site and Services:
          </p>
          <ul>
            <li>
              Request a Consultation. When you request a demo of free
              consultation, you may provide us with your name, email address,
              phone number, organization type, and industry, or other
              information.
            </li>
            <li>
              Create an Account. When you create an account with us, you may
              provide us with information on your organization, title/position,
              address, email, and phone number, or other information, and you
              will create a username and password for future log-ins.
            </li>
            <li>
              Submit an Order for a Product or Service. In order to receive data
              or services through you will in addition provide us with financial
              information (e.g., bank account, credit card number, or other
              payment information). We will share any payment information you
              provide with our payment processors.
            </li>
            <li>
              Interact with our Site, Staff, or Services. When you visit the
              site we collect information about your device ID, IP address,
              cookies, or other identifying digital footprint. When you log-in
              to your account, you provide us with your log-in information
              (e.g., username and password). When you contact us, send us any
              feedback, ask questions, provide comments, suggestions, ideas, or
              interact with us in any way or interact with our Staff you may
              provide us with your name, email address, and/or phone number, or
              other information about you or your entity.
            </li>
          </ul>
          <p>
            <strong>2. Information as You Navigate Our Site</strong>
          </p>
          <p>
            We automatically collect certain Personally Identifiable Information
            through your use of the Site and Services, such as the following:
          </p>
          <ul>
            <li>
              Usage Information. For example, the pages on the Site you access,
              the frequency of access, and what you click on while on the Site.
            </li>
            <li>
              Device or Internet Information. For example, hardware model,
              operating system, application version number, Device ID, IP
              address, browser, or other technical identifying information, such
              as information about whether the Site is accessed via a mobile
              device or tablet, the device type, and the carrier.
            </li>
            <li>
              Location Information. Location information from Site visitors on a
              city-regional basis.
            </li>
          </ul>
          <p>
            <strong>3. Third Party Information You Provide</strong>
          </p>
          <p>
            In some cases, we may receive certain Personally Identifiable
            Information from you about a third party. For example, as part of
            our registration process, you may provide the name, email address,
            and phone number of a third party. If you submit any Personally
            Identifiable Information about another individual to us, you are
            responsible for making sure you have the authority to do so and to
            allow us to use their Personally Identifiable Information in
            accordance with this Privacy Policy.
          </p>
          <p>
            <strong>4. How We Use Your Information</strong>
          </p>
          <p>
            We use the Personally Identifiable Information we collect to provide
            the Services to you, to improve our Site and Services, and to
            protect our legal rights. In addition, we may use the Personally
            Identifiable Information we collect to:
          </p>
          <ul>
            <li>Process your account registration;</li>
            <li>Process and fulfill your request or order;</li>
            <li>
              Contact you regarding our products and services that we feel may
              be of interest to you;
            </li>
            <li>
              Communicate with you about our Site or Services or to inform you
              of any changes to our Site or Services;
            </li>
            <li>Provide support;</li>
            <li>Maintain and improve our Site and Services;</li>
            <li>
              Protect the security and integrity of our Site and Services;
            </li>
            <li>
              Investigate, prevent, or take action regarding illegal activities,
              suspected fraud, situations involving potential threats to the
              physical safety of any person, or violations of an QR division's
              Terms and Conditions, and to otherwise fulfill our legal
              obligations;
            </li>
            <li>
              Monitor compliance with and enforce this Privacy Policy and any
              other applicable agreements and policies;
            </li>
            <li>Defend our legal rights and the rights of others;</li>
            <li>
              Fulfill any other purposes for which you provide it, or any other
              purpose we deem necessary or appropriate;
            </li>
            <li>Efficiently maintain our business; and</li>
            <li>Comply with applicable laws and regulations. </li>
          </ul>
          <p>
            <strong>5. How we Share Your Information</strong>
          </p>
          <p>
            We may share the Personally Identifiable Information we collect in
            the following ways:
          </p>
          <ul>
            <li>
              With vendors who perform data or Site-related services on our
              behalf (e.g., email, hosting, maintenance, backup, analysis,
              etc.);
            </li>
            <li>
              With vendors to prepare, deploy and analyze advertising content;
            </li>
            <li>To the extent that we are required to do so by law;</li>
            <li>
              In connection with any legal proceedings or prospective legal
              proceedings;
            </li>
            <li>
              To establish, exercise, or defend our or a third party's legal
              rights, including providing information to others for the purposes
              of fraud prevention;
            </li>
            <li>
              With any person who we reasonably believe may apply to a court or
              other competent authority for disclosure of that Personally
              Identifiable Information where, in our reasonable opinion, such
              court or authority would be reasonably likely to order disclosure
              of that Personally Identifiable Information;
            </li>
            <li>
              With any other person or entity as part of any business or asset
              sale, capital raise, equity transaction, merger, acquisition,
              bankruptcy, liquidation, or similar proceeding, or in preparation
              for any of these events;
            </li>
            <li>
              With our data partners who use the information to identify you,
              provide us information about you, or provide complimentary or
              other services to you or others;
            </li>
            <li>
              With any other person or entity where you consent to the
              disclosure; and
            </li>
            <li>
              For any other purpose disclosed by us when you provide the
              Personally Identifiable Information or for any other purpose we
              deem necessary, including to protect the health or safety of
              others.
            </li>
          </ul>
          <p>
            <strong>6. COOKIES AND OTHER TRACKING TECHNOLOGIES</strong>
          </p>
          <p>
            <strong>How We Use Cookies</strong>
          </p>
          <p>
            Like many companies, we use cookies and other tracking technologies
            (such as pixels, i-frames, and/or web beacons) (collectively,
            <strong>“Cookies”</strong>). We may use Cookies to:
          </p>
          <ul>
            <li>Estimate audience size and usage patterns;</li>
            <li>
              Understand and save your preferences for future visits, allowing
              us to customize the Site and Services to your individual needs;
            </li>
            <li>
              Advertise new content and services that relate to your interests;
            </li>
            <li>Keep track of advertisements and search engine results;</li>
            <li>
              Compile aggregate data about site traffic and site interactions to
              resolve issues and offer better site experiences and tools in the
              future; and
            </li>
            <li>
              Identify you on your first visit to the Site or recognize when you
              return to the Site. We set some Cookies ourselves, while separate
              entities set other Cookies. We use Third-Party Cookies from other
              entities to provide us with useful information about you, to help
              us improve our Site and Services, to conduct advertising, and to
              analyze the effectiveness of advertising.
            </li>
            <li>
              As cookie technology is phased-out, we will use newer, equivalent
              identification and tracking technologies.
            </li>
          </ul>
          <p>
            <strong>7. How You Can Opt Out of Cookies</strong>
          </p>
          <p>
            By using our Site, Tools, Services, or Data you consent to the our
            use of cookies as described in this Policy. You can block or
            minimize Cookies by changing your Internet browser settings to
            refuse all or some Cookies, using the services of a Virtual Private
            Network (VPN), using other anonymizers or tunnelers (such as Tor) or
            other similar technologies. If you choose to block all Cookies
            (including essential Cookies) you may not be able to access all or
            parts of the Site. Some cookies are effective immediately upon
            landing on our Site and the effectiveness of your Cookie blocking or
            minimizing technologies may vary with your choice of privacy systems
            and usage.
          </p>
          <p>
            <strong>8. Google Analytics</strong>
          </p>
          <p>
            We may use Google Analytics, a web analytics service provided by
            Google, Inc. Google Analytics uses Cookies or other tracking
            technologies to help us analyze how users interact with the Site and
            Services, compile reports on their activity, and provide other
            services related to their activity and usage. The technologies used
            by Google may collect information such as your IP address, time of
            visit, whether you are a returning visitor, and any referring
            website. The information generated by Google Analytics will be
            transmitted to and stored by Google and will be subject to Google's
            privacy policies. .
          </p>
          <p>
            <strong>Anti-Bot Technologies (e.g., Google reCAPTCHA)</strong>
          </p>
          <p>
            We may use anti-bot technologies, such as Google reCAPTCHA, to
            protect our Site from spam and abuse. These technologies use
            advanced risk analysis techniques to decipher humans and bots. For
            example, you may be asked to check a box indicating that you are not
            a robot. Your use of those technologies is subject to their Privacy
            Policy and{' '}
            <a href="/terms" target="_blank">
              Terms of Service.
            </a>
          </p>
          <p>
            <strong>“DO NOT TRACK” SIGNALS</strong>
          </p>
          <p>
            Some internet browsers incorporate a “Do Not Track” feature that
            signals to websites you visit that you do not want to have your
            online activity tracked. Given that there is not a uniform way that
            browsers communicate the “Do Not Track” signal, the Site may not
            currently interpret, respond to or alter its practices when it
            receives “Do Not Track” signals.
          </p>
          <p>
            <strong>9. Your Responsibility for Use.</strong> You represent that
            you will use Tools and Services provided by QR in conformity with
            all applicable laws and regulations. Use of data or services
            provided by QR for an unlawful purpose is a violation of our Terms
            of Use. QR is not responsible for your lawful or unlawful use of the
            Tools and Services. QR will fully comply with government authorities
            to aid in enforcing existing laws and regulations and suspend your
            access to Tools and Services pursuant to our Terms of Use.
          </p>
          <p>
            <strong>10. Review and Request Changes to Your Information:</strong>{' '}
            In some jurisdictions you have the right to view certain Personally
            Identifiable Information we have collected and which is associated
            with your account. To review or request changes to any of your
            Personally Identifiable Information, please contact us at
            support@quantumred.io.
          </p>
          <p>
            <strong>11. Marketing Communications:</strong> To unsubscribe from
            our marketing emails, please click the unsubscribe link included in
            the footer of our emails. You also may submit a request to us at
            support@quantumred.io. Some mandatory servicing emails are not
            covered by a request to unsubscribe.
          </p>
          <p>
            <strong>12. Security:</strong> We maintain commercially reasonable
            security measures to protect the Personally Identifiable Information
            we collect and store from loss, misuse, destruction, or unauthorized
            access. However, no security measure or modality of data
            transmission over the Internet is 100% secure. Although we strive to
            use commercially acceptable means to protect your Personally
            Identifiable Information, we cannot guarantee absolute security.
          </p>
          <p>
            <strong>13. Third Party Links:</strong> The Site and Services may
            contain links that will let you leave the Site and Services and
            access another website. Linked websites are not under our control.
            This Privacy Policy applies solely to Personally Identifiable
            Information that is acquired by us on this Site. We accept no
            responsibility or liability for these other websites.
          </p>
          <p>
            <strong>14. Children's Privacy:</strong> The Site and Services are
            not intended for children. We do not knowingly collect, use, or
            disclose personal information from children under 18.
          </p>
          <p>
            <strong>15. International Users:</strong> The QR website is only for
            users in the United States.
          </p>
          <p>
            <strong>16. Accessibility:</strong> We are committed to ensuring
            this Privacy Policy is accessible to individuals with disabilities.
            If you wish to access this Privacy Policy in an alternative format,
            please contact us as described below.
          </p>
          <p>
            <strong>17. NOTICE TO CALIFORNIA RESIDENTS</strong>
          </p>
          <p>
            The California Consumer Privacy Act of 2018, as amended by the
            California Privacy Rights Act (collectively the “CCPA” or the “Act”)
            confers privacy rights on California consumers and imposes
            corresponding obligations on businesses subject to the Act as
            described in this Section. If you are a California resident, you may
            ask businesses to disclose what personal information they have about
            you and what they do with that information, to delete your personal
            information, to direct businesses not to sell or share your personal
            information, to correct inaccurate information that they have about
            you, and to limit businesses' use and disclosure of your sensitive
            personal information: <strong>Right to know:</strong> You can
            request that a business disclose to you: (1) the categories and/or
            specific pieces of personal information they have collected about
            you, (2) the categories of sources for that personal information,
            (3) the purposes for which the business uses that information, (4)
            the categories of third parties with whom the business discloses the
            information, and (5) the categories of information that the business
            sells or discloses to third parties. You can make a request to know
            up to twice a year, free of charge.{' '}
            <strong>Right to delete:</strong> You can request that businesses
            delete personal information they collected from you and tell their
            service providers to do the same, subject to certain exceptions
            (such as if the business is legally required to keep the
            information). <strong>Right to opt-out of sale or sharing:</strong>{' '}
            You may request that businesses stop selling or sharing your
            personal information (“opt-out”), including via a user-enabled
            global privacy control. Businesses cannot sell or share your
            personal information after they receive your opt-out request unless
            you later authorize them to do so again.{' '}
            <strong>Right to correct:</strong> You may ask businesses to correct
            inaccurate information that they have about you.
            <strong>
              Right to limit use and disclosure of sensitive personal
              information:
            </strong>
            You can direct businesses to only use your sensitive personal
            information (for example, your social security number, financial
            account information, your precise geolocation data, or your genetic
            data) for limited purposes, such as providing you with the services
            you requested. You also have the right to be notified, before or at
            the point businesses collect your personal information, of the types
            of personal information they are collecting and what they may do
            with that information. Generally, businesses cannot discriminate
            against you for exercising your rights under the CCPA. Businesses
            cannot make you waive these rights, and any contract provision that
            says you waive these rights is unenforceable. As described in this
            policy and in our Terms of Use, QR will sell personal information it
            has collected to our clients (i.e., “third parties” under CCPA), for
            their business and commercial purposes. More specifically, QR
            licenses personal information about donor prospects to our clients
            for the purpose of enabling and improving their fundraising. QR may
            share personal information to service providers and contractors, for
            QR's own operational business purposes. QR does not sell personal
            information about consumers younger than 18. QR may use personal
            information from minors younger than 18 to suppress or remove
            matching records from our lists. QR does not engage in profiling in
            furtherance of “decisions that produce legal or similarly
            significant effects.” As permitted by CCPA regulations, QR may treat
            correction requests as a request to delete. QR may use Sensitive
            Personal Information as a part of its marketing and advertising
            products. Pursuant to the CCPA, California consumers have a right to
            request a business limit use of “sensitive personal information”
            about them that QR has collected or maintained. QR may treat a
            request to limit our use of sensitive personal information as a
            request to delete. QR will not discriminate against consumers who
            request access to personal information about them, request deletion,
            or request that QR opt them out of future sales. The CCPA allows
            California consumers to use authorized agents to submit requests to
            QR on their behalf. QR requires that the authorized agent provide
            written authorization from the consumer to act on the consumer's
            behalf. In the case of an authorized agent's request for access to
            personal information, the resulting report will be delivered to the
            consumer directly. Even assuming a verifiable request, QR will
            retain certain personal information in our systems as necessary to
            comply with a legal obligation (including ensuring the deletion
            request is honored and record-keeping obligations), detect security
            incidents, protect against malicious, deceptive, fraudulent, or
            illegal activity, or prosecute those responsible for the activity.
            In such cases, QR will retain only the minimum amount of information
            that does not qualify for a statutory or regulatory exception, and
            only use the remaining data for the purposes provided by the
            statutory or regulatory exception. QR will delete the information
            collected as part of the identity verification process as soon as
            practical after fulfilling your request. To exercise any of your
            rights, send your request to support@quantumred.io. To respond to
            your right to know request, the CPA permits QR to verify your
            identity. A QR associate will call you to administer an
            authentication test in which you will be required to answer certain
            questions to verify your identity.
          </p>
          <p>
            <strong>18. NOTICE TO COLORADO RESIDENTS</strong>
          </p>
          <p>
            The Colorado Privacy Act (the “CPA”) confers privacy rights on
            Colorado consumers and imposes corresponding obligations on
            businesses subject to the CPA. The following is intended to explain
            a consumer's rights and the obligations QR has under the CPA. As a
            consumer under the CPA, you have the right to request that QR
            disclose what personal information we collect, the purpose for which
            we use the personal information, as well as the categories of
            personal information that we disclose and sell about you. We call
            this your “right to know.” To take advantage of your right to know,
            you can submit a request to support@quantumred.io. To respond to
            your right to know request, the CPA permits QR to verify your
            identity. A QR associate will call you to administer an
            authentication test in which you will be required to answer certain
            questions to verify your identity.
          </p>
          <p>
            <strong>19. NOTICE TO NEVADA RESIDENTS</strong>
          </p>
          <p>
            Nevada law allows Nevada residents to opt out of the sale of certain
            types of personal information. Subject to several exceptions, Nevada
            law defines “sale” to mean the exchange of certain types of personal
            information for monetary consideration to another person. If you are
            a Nevada resident, you may submit a verified request to opt out of
            sales and we will record your instructions and incorporate them in
            the future if our policy changes. You may send opt-out requests to
            support@quantumred.io.
          </p>
          <p>
            <strong>20. NOTICE TO VIRGINIA RESIDENTS</strong>
          </p>
          <p>
            The Virginia Consumer Data Protection Act (“VCDPA”) provides
            Virginia residents with the right to receive certain disclosures
            regarding the personal data we process about them. For purposes of
            this section, personal data means any information that is linked or
            reasonably linkable to an identified or identifiable natural person.
            Personal data does not include de-identified or publicly available
            information as the VCDPA defines those terms. For a discussion of
            the categories of personal data we process, the purposes for our
            processing, the categories of personal data that we share with third
            parties, and the categories of third parties with whom we share
            personal data, please see Sections 1 through 3, above. If you are a
            Virginia resident, the VCDPA grants you the right to: (1) confirm
            whether or not we are processing your personal data and to access
            such personal data; (2) correct inaccuracies in your personal data,
            taking into account the nature of the personal data and the purposes
            of the processing of your personal data; (3) delete personal data
            you have provided or we have obtained about you; (4) obtain a copy
            of your personal data that you previously provided to us in a
            portable and, to the extent technically feasible, readily usable
            format that allows you to transmit the data to another controller
            without hindrance, where the processing is carried out by automated
            means; and (5) opt out of the processing of your personal data for
            purposes of (i) targeted advertising, (ii) the sale of personal
            data, or (iii) profiling in furtherance of decisions that produce
            legal or similarly significant effects concerning you. We sell
            personal data to third parties as defined in the VCDPA. We also
            process personal data for targeted advertising. To exercise your
            rights to confirm whether we have your personal data, to correct
            inaccuracies, or to obtain a copy of your personal data, please
            email us at support@quantumred.io with your name, the fact that you
            are a Virginia resident, and specifying the right(s) you wish to
            invoke. If you wish to opt out of profiling, processing, or sale of
            your personal data. Please email us at support@quantumred.io.
          </p>
          <p>
            <strong>21. NOTICE TO CONNECTICUT RESIDENTS</strong>
          </p>
          <p>
            The Connecticut Data Privacy Act (the “CTDPA”) confers privacy
            rights on Connecticut consumers and imposes corresponding
            obligations on businesses subject to the CTDPA. The following is
            intended to explain a consumer's rights and the obligations QR has
            under the CTDPA. As a consumer under the CTDPA, you have the right
            to request that QR disclose what personal information we collect,
            the purpose for which we use the personal information, as well as
            the categories of personal information that we disclose and sell
            about you. We call this your “right to know.” To take advantage of
            your right to know, you can submit a request to
            support@quantumred.io. To respond to your right to know request, the
            CTDPA permits QR to verify your identity. A QR associate will call
            you to administer an authentication test in which you will be
            required to answer certain questions to verify your identity.
          </p>
          <p>
            <strong>23. How to Contact Us:</strong>
          </p>
          <p>
            To contact us for questions or concerns about our privacy policies
            or practices, please contact us at support@quantumred.io.
          </p>
          <Legalese marginTop />
        </Col>
      </Row>
    </>
  );
};

export default Privacy;

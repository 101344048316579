import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Home from './pages/Home';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route index element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

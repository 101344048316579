import React, { useEffect, useState } from 'react';
import sample from '../../assets/sample.png';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../style.css';
import { Plans } from '../../components/Plans';
import Legalese from '../../components/Legalese';

const Home = () => {
  const [envJSON, setEnvJSON] = useState(null);

  const signupButton = () => (
    <Row className="my-4">
      <Col className="text-center">
        <Button href={envJSON.VLOG_URL}>Sign Up</Button>
      </Col>
    </Row>
  );

  useEffect(() => {
    fetch('/env.json')
      .then(res => res.json())
      .then(data => {
        setEnvJSON(data);
      });
  }, []);

  if (!envJSON) return null;
  return (
    <Container fluid>
      <Row className="mt-0 mt-md-5">
        <Col md={2}></Col>
        <Col className="bordered p-5">
          <h1 className="text-dark text-center">
            Who visited your website? We can tell you.
          </h1>
          <Row>
            <Col>
              <h4 className="text-dark text-center">
                Introducing <span className="text-danger">Visitor Log</span> by
                QuantumRed
              </h4>
              <h4 className="text-dark text-center">
                Website visitor identification
              </h4>

              <h4 className="text-dark text-center mt-5">
                <ListGroup>
                  <ListGroup.Item>Identify supporters</ListGroup.Item>
                  <ListGroup.Item>Meet new donors</ListGroup.Item>
                  <ListGroup.Item>
                    Track media or competitor visits
                  </ListGroup.Item>
                </ListGroup>
              </h4>
            </Col>
          </Row>
          {signupButton()}
          <Row>
            <Col>
              <h4 className="text-dark">
                Over 90% of visitors to your website do not give you their email
                or phone number. We identify these anonymous visitors and
                provide you with detailed contact information so you can reach
                out to them. Never miss another potential supporter, donor, or
                voter.
              </h4>
            </Col>
          </Row>
          {signupButton()}
          <Row>
            <Col>
              <h4 className="text-dark">How it works:</h4>
              <h4 className="text-dark">
                <ListGroup as="ol" numbered>
                  <ListGroup.Item>Sign up</ListGroup.Item>
                  <ListGroup.Item>
                    We give you a unique pixel to put your on your website
                  </ListGroup.Item>
                  <ListGroup.Item>
                    You receive daily emails with your website visitor records
                  </ListGroup.Item>
                  <ListGroup.Item>
                    Choose to receive Basic or Enhanced visitor data
                  </ListGroup.Item>
                </ListGroup>
              </h4>
            </Col>
          </Row>
          {signupButton()}
          <Row>
            <Col>
              <h4 className="text-dark">Sample Data:</h4>
              <Image fluid src={sample} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <h4 className="text-dark">Pricing:</h4>
              <Plans envJSON={envJSON} />
            </Col>
          </Row>
          <Legalese marginTop />
        </Col>
        <Col md={2}></Col>
      </Row>
    </Container>
  );
};

export default Home;

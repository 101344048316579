import Legalese from '../../components/Legalese';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Header from '../../components/Header';

const Terms = () => {
  return (
    <>
      <Header />
      <Row className="text-dark position-relative mt-5">
        <Col lg={1} />
        <Col lg={10}>
          <p>
            <strong>
              QuantumRed (QR Advisors, LLC) Terms of Use, Conditions of Use, and
              Binding Agreement
            </strong>
          </p>
          <p>Last Updated January 10, 2024</p>
          <p>
            <strong>THIS IS A BINDING AGREEMENT</strong>
          </p>
          <p>
            These User Terms of Use (<strong>“Terms”</strong>) govern the use by
            you (the
            <strong>“Client”</strong>) of QuantumRed (QR Advisors, LLC) by all
            users. The Tools and Services (defined below) provided on this site
            are products of QR Advisors, LLC (<strong>“QR”</strong>). By using
            the Tools or Services you acknowledge and agree to these Terms.
            References to “QR,” “we,” and “us” herein include QR's past,
            present, and future subsidiaries, internal products, parent
            companies, members, and affiliates, as well as QR's and each of
            these entities' agents, employees, officers, directors, predecessors
            in interest, successors, and assigns. References to "You" include
            all a Client's authorized or unauthorized users or beneficiaries of
            services provided by QR to You or Your representative or agent.
          </p>
          <p>
            <strong>
              If you do not agree to be bound by all of these Terms, then you
              may not use the Tools or Services. Your initial or continued use
              of the Tools or Services constitutes your continuous agreement to
              these Terms.
            </strong>{' '}
            Use of individual Tools and Software-as-a-Service (such as Benjamin,
            DRE, Visitor Log, or other technologies) may be subject to their own
            Privacy Policies and Terms of Use. If you have entered into a
            separate
            <strong>Product Agreement</strong> (such as for the Visitor Log
            product) or a
            <strong>
              Technology, Artificial Intelligence, and Data (TAD) Agreement
            </strong>{' '}
            with QR, then that agreement may supersede these Terms except with
            respect to your general use of our Site.{' '}
          </p>
          <p>
            IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE TO THESE
            TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY
            DISPUTE BETWEEN YOU AND QR THROUGH BINDING, INDIVIDUAL ARBITRATION
            RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY BELOW FOR DETAILS
            REGARDING ARBITRATION.
          </p>
          <p>
            <strong>1. How QR Works.</strong>
          </p>
          <p>
            QR offers digital tools, Software-as-a-Service and other digital
            resources (collectively the QR <strong>“Tools”</strong>) and
            consulting services (the <strong>“Services”</strong>) to Clients.
            Our Clients include nonprofits organizations, social organizations,
            private companies, advocacy groups, social movements, political
            campaigns and committees, their staff, their consultants, and others
            delegated access to the Tools or Services by our Clients (the
            <strong>“Authorized Users”</strong>).
          </p>
          <p>
            <strong>
              2. Agreement, License Term, Termination, Notices, and Changes to
              Terms
            </strong>
          </p>
          <p>
            These terms are a binding legal commitment between you and QR, so
            please read them carefully. If you are accessing and using the Tools
            and Services as an Authorized User on behalf of a company (such as
            your employer) or other legal entity, you represent and warrant that
            you have the authority to bind that company or other legal entity to
            these Terms. In that case, “you” and “your” will refer to that
            company or other legal entity. This Agreement shall last for as long
            as you use QR's Tools and Services. Contract length can be found in
            your TAD Agreement or Product Agreement. You shall have no
            termination rights other than to cease your use of the Tools and
            Services, unless expressly stated in your TAD Agreement or Product
            Agreement. If you do not agree to be bound by these terms, you may
            not use the Tools and Services. We may modify or update these Terms
            at any time, in our sole discretion. If you continue to use the
            Tools and Services you are indicating that you agree to be bound by
            the modified Terms. If you do not agree to be bound by the modified
            Terms, then you may not use the Tools and Services. Our Tools and
            Services, are evolving over time and we may change or discontinue
            all or any part, at any time and without notice, at our sole
            discretion.
          </p>
          <p>
            <strong>3. Privacy Policy</strong>
          </p>
          <p>
            Please refer to our Privacy Policy for information on how we
            collect, use and disclose information from users on the site and as
            part of the Information we provide. You acknowledge and agree that
            your use of the Tools and Services is subject to our Privacy Policy.
            Your use of the QR Tools and Services constitutes your agreement and
            acknowledgement that you have read and agree to be bound by the
            Privacy Policy and these Terms.{' '}
          </p>
          <p>
            <strong>4. Your QR Account</strong>
          </p>
          <p>
            You may use the Tools or Services only if you are 18 years or older,
            capable of forming a binding legal contract with QR, and are not
            barred from using the Services under applicable law. You may create
            an account (<strong>“Account”</strong>) to use our Tools or
            Services). You agree to provide us with accurate and complete
            information for your Account and to update such information, as
            needed, to keep it accurate, complete and up-to-date. You agree that
            you won't disclose your Account password to anyone and you'll notify
            us immediately of any unauthorized use of your Account. You agree
            that you are responsible for all activities that occur under your
            Account, including the activities of any users accessing the Tools
            using your credentials, whether or not you know about or authorized
            them to do so.
          </p>
          <p>
            <strong>5. Client Data and Information</strong>
          </p>
          <p>
            You acknowledge that you have read our Privacy Policy and understand
            that it sets forth how we will collect, store, share and use your
            Personal Data (as defined therein), both when you are a user of the
            Tools or Services and how personal data is used. Use of individual
            Tools and Software-as-a-Service (such as Benjamin, DRE, Visitor Log,
            or other technologies) may be subject to their own Privacy Policies
            and Terms of Use. If you do not agree with the applicable Privacy
            Policies and Terms of Use, then you must stop using the QR Tools and
            Services immediately. Your use of the QR Tools and Services
            constitutes your agreement and acknowledgement that you have read
            and agree to be bound by the Privacy Policy and these Terms.{' '}
          </p>
          <p>
            <strong>6. Payment</strong>
          </p>
          <p>
            For technology tools, Payment terms are contained in your order form
            at checkout. You will be billed to the credit card on file. You
            agree to pay QR the amount that is specified in the order form at
            checkout in accordance with these Terms and any other terms
            associated with such payment plan, and you authorize QR (or our
            third party payment processor) to bill your payment method on a
            periodic basis in accordance with such terms. Unless otherwise
            stated at checkout or any quote provided by QR, prices quoted shall
            be good for a period of thirty (30) days. Full payment is due at the
            time of checkout. For Services, payment terms are contained in your
            Services or TAD Agreement or the applicable invoice provided to you.
            All amounts paid are non-refundable and we reserve the right to
            change our prices in the future.
          </p>
          <p>
            <strong>7. Acceptable Use Policy</strong>
          </p>
          <p>
            Without limiting any provision of these Terms, you may only use the
            Tools and Services for purposes authorized by law in the
            jurisdiction in which you operate. For Tools and Services that
            include data delivery, you represent that you will not use the QR
            Tools and Services for unauthorized purposes, including: personal
            research or curiosity, cyberstalking, for use in family, marital,
            divorce, or custody disputes, to evaluate a consumer's eligibility
            for credit or insurance to be used primarily for personal, family,
            or household purposes, to evaluate a person's eligibility for
            employment or volunteering purposes, to evaluate a person's
            eligibility for a government license or benefit, to evaluate a
            person for renting a dwelling property, or for any other purpose
            specified in the Fair Credit Reporting Act (the “FCRA”), Federal
            Trade Commission or court interpretations of the FCRA, or similar
            state statutes. You further represent that the Tools and Services
            will not be used to engage in activities that would violate
            applicable local, state, national or international law, or any
            regulations having the force of law, including the laws,
            regulations, and ordinances of any jurisdiction from which you
            accessed the Tools and Services or the laws, regulations, and
            ordinances applicable to the location of the individuals or
            organizations searched for or Data searched. Further, you represent
            that the Tools and Services will not be used to make any phone call
            or send any email or text message that does not comply with
            CAN-SPAM, the Telephone Consumer Protection Act or any other
            applicable federal or state law governing fundraising or marketing
            solicitations. You acknowledge that any Data obtained by you on
            individuals as a result of your use of the Tools and Services does
            not include that individuals opt-in or consent to be contacted by
            you. You are solely responsible for ensuring that telephone calls
            made or email or text messages sent using information obtained from
            Tools or Services are in compliance with CAN-SPAM, the Telephone
            Consumer Protection Act, and all other applicable federal or state
            laws. QR does not scrub any phone numbers or contact information
            displayed on the Tools or Services through the National Do Not Call
            Registry, similar state-level registries, or any other do-not-call
            registries. The Data belongs to you and you represent that your use
            of the Tools and Services will honor any and all rights consumers
            may have under these and similar laws or registries.
          </p>
          <p>
            You acknowledge that neither QR nor you are a consumer reporting
            agency nor subject to the FCRA. The Service is not intended for use
            by persons under the age of 18. You must ensure that each of your
            Authorized Users is aware of and complies with the requirements and
            restrictions set forth in this paragraph. You are responsible for
            all activities that occur under your account (or any of your
            Authorized Users' accounts), and for Authorized Users' compliance
            with these Terms. You agree to (a) have sole responsibility for the
            accuracy, quality, integrity, legality, reliability, and
            appropriateness of all content and data that you (or any of your
            Authorized Users) input into or store using the Tools or Services;
            (b) use commercially reasonable efforts to prevent unauthorized
            access to, or use of, the Tools or Services, and notify QR promptly
            of any such unauthorized access or use; and (c) comply with this
            Acceptable Use Policy and all applicable local, state, federal and
            foreign laws in using the Tools or Services. QR reserves the right
            to terminate and pursue legal action against any user for a
            violation of this acceptable use policy.{' '}
          </p>
          <p>
            <strong>8. Feedback</strong>
          </p>
          <p>
            We welcome feedback, comments, and suggestions for improvements to
            our Tools or Services. If you provide feedback or recommendations to
            us, you grant to us a non-exclusive, worldwide, perpetual,
            irrevocable, fully-paid, royalty-free, sublicensable and
            transferable license to use the content of your feedback for any
            purpose, including to improve our Tools or Services without any
            obligation to compensate you for providing ideas or feedback.
          </p>
          <p>
            <strong>9. Disclaimer of Warranty and Indemnity</strong>
          </p>
          <p>
            QR is not subject to liability for truth, accuracy, or fitness of
            the Tools, Services, or Data conveyed or for its completeness or
            errors or mistakes made by humans or machines in the creation of the
            Information or Data. QR will not be subject to liability for your
            use of the Information or Data, including marketing or outreach
            costs expended by you in reliance on Information or Data provided by
            QR. QR may take reasonable steps to control the continuing quality
            of the Information and Data, and the application of such quality
            control procedures constitutes the full extent of warranty
            concerning the information provided. The client acknowledges that it
            may be necessary or advisable from time to time, in the ordinary
            course of QR's business, for QR to add, delete, and substitute
            Information it makes available to its clients including the Client.
            QR expressly reserves the right to revise the Information and to
            make changes in content. QR MAKES NO WARRANTIES EXCEPT FOR THOSE SET
            FORTH ABOVE AND DISCLAIMS ALL OTHER WARRANTIES, WHETHER EXPRESS OR
            IMPLIED, INCLUDING BUT NOT LIMITED TO IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. QR MAKES NO
            WARRANTY OF ANY KIND THAT THE SERVICES, OR ANY PRODUCTS OR RESULTS
            OF THE USE THEREOF, WILL MEET THE CLIENT'S OR ANY OTHER PERSON'S
            REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED
            RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER
            SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR
            ERROR-FREE. The Tools, Services, and Data are provided “AS IS.” We
            make no warranty that they will meet your requirements or be
            available on an uninterrupted, secure, or error-free basis. We make
            no warranty regarding the quality, accuracy, timeliness,
            truthfulness, completeness or reliability of any Information or
            Data. By using the Tools or Services, or by taking an action using
            the Data you obtain using our Tools and Services you acknowledge
            that QR's sole and exclusive liability and Client's sole and
            exclusive remedy for breach of the limited warranty outlined in this
            Section shall be reperformance of the affected services or, at QR's
            option, a refund to Client of a portion of the fees previously paid
            by Client corresponding to the defective services.{' '}
            <strong>
              You will indemnify and hold harmless QR and its officers,
              directors, employees, agents, parent, subsidiaries, and affiliates
              from and against any claims, disputes, demands, liabilities,
              damages, losses, and costs and expenses, including, without
              limitation, reasonable legal and accounting fees arising out of or
              in any way connected with (i) your access to or use of the Tools,
              Services, and Data or (ii) your violation of these Terms.
            </strong>
          </p>
          <p>
            <strong>10. Limitation of Liability</strong>
          </p>
          <p>
            IN NO EVENT SHALL QR BE LIABLE TO CLIENT OR ANY THIRD PARTY FOR ANY
            (A) LOSS OF USE, INCREASED COSTS, DIMINUTION IN VALUE OR LOST
            BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (B) LOSS OF GOODWILL OR
            REPUTATION; (C) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR
            RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (D)
            FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR
            PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT
            (INCLUDING NEGLIGENCE) OR OTHERWISE, REGARDLESS OF WHETHER SUCH
            DAMAGES WERE FORESEEABLE AND WHETHER OR NOT QR HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES AND NOTWITHSTANDING THE FAILURE OF
            ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE. IN NO EVENT
            SHALL QR'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS
            AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT,
            TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL OF THE
            AMOUNTS PAID TO QR FOR THE INFORMATION AND SERVICES THAT ARE THE
            SUBJECT OF THE APPLICABLE CLAIM. QR shall not be liable or
            responsible to Client, nor be deemed to have defaulted or breached
            this Agreement, for any failure or delay in fulfilling or performing
            any term of this Agreement when and to the extent such failure or
            delay is caused by or results from acts or circumstances beyond the
            reasonable control of QR including, without limitation, acts of God,
            flood, fire, earthquake, explosion, governmental actions, war,
            invasion or hostilities (whether war is declared or not), terrorist
            threats or acts riot, or other civil unrest, national emergency,
            revolution, insurrection, epidemic, pandemic, lockouts, strikes or
            other labor disputes (whether or not relating to either party's
            workforce), or restraints or delays affecting carriers or inability
            or delay in obtaining supplies of adequate or suitable materials,
            materials or telecommunication breakdown or power outage.
          </p>
          <p>
            <strong>11. Intellectual Property</strong>
          </p>
          <p>
            Data accessed using the Tools and Services belongs to you, but you
            agree that the Tools & Services themselves are licensed to you by QR
            are and will remain the property of QR. You do not have any license
            or right to use any trade or service mark displayed in any part of
            the Tools or Service without the express written permission of QR.
            QR's intellectual property rights in and to the product are
            protected by the United States copyright and trademark laws, and the
            Client agrees that its employees are not authorized to reproduce,
            copy, republish, upload to a third party or distribute any part of
            the Tools, Service, Information, except as provided under this
            Agreement.
          </p>
          <p>
            <strong>12. Your Data, Information, and content.</strong>
          </p>
          <p>
            Under several circumstances, in interacting with our Tools and
            Service, you may provide QR with data. For example, users of the
            Visitor Log feature use our pixel to obtain information on visitors
            to your website. That information obtained belongs to you and is
            your data. Additionally, if you receive data services from QR, you
            may provide QR with data or customer lists to train algorithms,
            perform machine learning, perform data supplementation or
            augmentation. Anytime you provide data on your customers, clients,
            prospects, or targets to QR, the following provisions apply: you
            hereby grant to QR a non-exclusive, transferable, sublicensable,
            worldwide, royalty-free license to use, copy, modify, and create
            derivative work as needed to operate and provide our Tools or
            Service, with respect to provisions otherwise specified in our
            Privacy Policy. You are solely responsible for all your content and
            Data. You represent and warrant that you have all rights and
            consents, and will obtain all rights and consents, with respect to
            all of your content and Data to the extent necessary (i) for you and
            QR to comply with all applicable laws, rules and regulations
            including, without limitation, all applicable data protection and
            privacy laws; and (ii) for you to grant us the license rights in
            your content under these Terms. You also represent and warrant that
            neither your content, your Customer Data, nor your use and provision
            of your content or Customer Data to be made available through the
            Tools or Services, nor any use of your content or Customer Data by
            QR on or through the Tools or Services will infringe, misappropriate
            or violate a third party's intellectual property rights, or rights
            of publicity or privacy, or result in the violation of any
            applicable law or regulation.
          </p>
          <p>
            You can remove your content by specifically deleting it or
            requesting that QR delete it in accordance with our Privacy Policy.
          </p>
          <p>
            <strong>13. Publicity.</strong> Client agrees that QR may include
            Client's name, organization, or brand in a list of QR customers,
            online or in promotional materials. Customer also agrees that QR may
            verbally reference Client as a customer of the QR products or
            services that are the subject of this Agreement.
          </p>
          <p>
            <strong>14. Dispute Resolution.</strong>
          </p>
          <p>
            YOU AND QR AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY
            IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
            MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further,
            if the parties' dispute is resolved through arbitration, the
            arbitrator may not consolidate another person's claims with your
            claims, and may not otherwise preside over any form of a
            representative or class proceeding. If this specific provision is
            found to be unenforceable, then the entirety of this Dispute
            Resolution section will be null and void. The parties shall attempt
            to resolve any disputes related to these Terms or an order form
            (checkout) by first escalating the dispute to a level of management
            in each party's organization that is removed from the day-to-day
            implementation or the prior negotiation of the relationship between
            the parties. If the parties cannot resolve the dispute pursuant to
            the foregoing sentence, as the exclusive means for initiating any
            adversarial proceedings relating to these Terms or order form
            (checkout), a party may demand that the dispute be resolved by
            arbitration administered by a nationally recognized arbiter to be
            heard in Northern Virginia under the rules of the American
            Arbitration Association. Neither party may commence any adversarial
            proceeding in any other forum. The arbiter's determination will be
            final and binding on the parties, may be entered in a court of
            competent jurisdiction, and may be enforced in other jurisdictions.
            Each party hereby consents to any dispute related to these Terms or
            an Order Form being heard and resolved exclusively pursuant to this
            section. Nothing in this section is intended to be construed to
            prevent a party from seeking injunctive or similar interim relief
            from a court of competent jurisdiction.
          </p>
          <p>
            If your claim is for U.S. $10,000 or less, you may choose whether
            the arbitration will be conducted solely on the basis of documents
            submitted to the arbitrator, through a telephonic or
            video-conference hearing, or by an in-person hearing as established
            by the AAA Rules. If your claim exceeds U.S. $10,000, the right to a
            hearing will be determined by the AAA Rules. The parties agree that
            the arbitrator will have exclusive authority to decide all issues
            relating to the interpretation, applicability, enforceability, and
            scope of this arbitration agreement.
          </p>
          <p>
            <strong>15. Miscellaneous</strong>
          </p>
          <p>
            This Agreement shall be governed by and construed, and enforced per
            the laws of the the State of Delaware, excluding its choice of law
            rules regardless of its place of execution. In the event of a
            dispute hereunder, the parties agree to submit to the exclusive
            jurisdiction of the state courts of, and federal courts sitting in,
            the State of Delaware.
          </p>
          <p>
            No waiver of any term or condition of this Agreement shall be deemed
            to be a waiver of any subsequent breach of any term or condition.
          </p>
          <p>
            If any part of this Agreement shall be held unenforceable, the
            remainder of this Agreement will nevertheless remain in full force
            and effect.
          </p>
          <p>
            You may not assign or transfer these Terms, by operation of law or
            otherwise, without QR's prior written consent. Any attempt by you to
            assign or transfer these Terms, without such consent, will be null.
            QR may freely assign or transfer these Terms without restriction.
            Subject to the foregoing, these Terms will bind and inure to the
            benefit of the parties, their successors and permitted assigns.
          </p>
          <p>
            Any notices or other communications provided by QR under these
            Terms, including those regarding modifications to these Terms, will
            be given: (i) via email; or (ii) by posting to the Tools or updating
            these Terms or other relevant policy. For notices made by e-mail,
            the date of receipt will be deemed the date on which such notice is
            transmitted.
          </p>
          <p>
            The Tools or Services may contain links to third-party websites or
            resources. We provide these links only as a convenience and are not
            responsible for the content, products or Tools or Services on or
            available from those websites or resources or links displayed on
            such websites. You acknowledge sole responsibility for and assume
            all risk arising from, your use of any third-party websites or
            resources.
          </p>
          <p>
            QR's failure to enforce any right or provision of these Terms will
            not be considered a waiver of such right or provision. The waiver of
            any such right or provision will be effective only if in writing and
            signed by a duly authorized representative of QR. Except as
            expressly set forth in these Terms, the exercise by either party of
            any of its remedies under these Terms will be without prejudice to
            its other remedies under these Terms or otherwise.
          </p>
          <p>
            Without limiting any other terms of these Terms, you agree not to do
            any of the following
          </p>
          <p>
            <ul>
              <li>
                Use, display, mirror or frame the Tools or Services or any
                individual element within the Tools or Services, QR's name, any
                QR trademark, logo or other proprietary information, or the
                layout and design of any page or form contained on a page,
                without QR' express written consent;
              </li>
              <li>
                Access, tamper with, or use non-public areas of the Tools or
                Services, QR computer systems, or the technical delivery systems
                of QR's providers;
              </li>
              <li>
                Attempt to probe, scan or test the vulnerability of any QR
                system or network or breach any security or authentication
                measures;
              </li>
              <li>
                Avoid, bypass, remove, deactivate, impair, descramble or
                otherwise circumvent any technological measure implemented by QR
                or any of QR's providers or any other third party (including
                another user) to protect the Tools or Services or content;
              </li>
              <li>
                Attempt to access or search the Tools or Services or content or
                download content from the Tools or Services through the use of
                any engine, software, tool, agent, device or mechanism
                (including spiders, robots, crawlers, data mining tools or the
                like) other than the software and/or search agents provided by
                QR or other generally available third-party web browsers;
              </li>
              <li>
                Send any unsolicited or unauthorized advertising, promotional
                materials, email, junk mail, spam, chain letters or other form
                of solicitation;
              </li>
              <li>
                Use any meta tags or other hidden text or metadata utilizing a
                QR trademark, logo URL or product name without QR' express
                written consent;
              </li>
              <li>
                Use the Tools or Services or any portion thereof in any manner
                not permitted by law and these Terms;
              </li>
              <li>
                Forge any TCP/IP packet header or any part of the header
                information in any email or newsgroup posting, or in any way use
                the Tools or Services to send altered, deceptive or false
                source-identifying information;
              </li>
              <li>
                Attempt to decipher, decompile, disassemble or reverse engineer
                any of the software used to provide the Tools or Services;
              </li>
              <li>
                Interfere with, or attempt to interfere with, the access of any
                user, host or network, including, without limitation, sending a
                virus, overloading, flooding, spamming, or mail-bombing the
                Tools or Services;
              </li>
              <li>
                Impersonate or misrepresent your affiliation with any person or
                entity;
              </li>
              <li>Violate any applicable law or regulation; or</li>
              <li>
                Encourage or enable any other individual to do any of the
                foregoing.
              </li>
            </ul>
          </p>
          <p>
            We reserve the right to monitor your use of the Tools & Services or
            to review or edit any of your use to ensure compliance with these
            Terms, and to comply with applicable law or other legal
            requirements. We reserve the right, but are not obligated, to remove
            or disable access to any content, at any time and without notice,
            including, but not limited to, if we, at our sole discretion,
            consider any content to be objectionable or in violation of these
            Terms. We have the right to investigate violations of these Terms.
            We may also consult and cooperate with law enforcement authorities
            to prosecute users who violate the law. QR respects copyright law
            and expects its users to do the same. It is QR's policy to terminate
            in appropriate circumstances Clients, Account holders, or Authorized
            Users who infringe the rights of copyright holders. If you are aware
            of any infringement taking place on the Tools or Services or wish to
            notify us of any infringement of your intellectual property, please
            notify us at support@quantumred.io
          </p>
          <p>
            <strong>Contact Us</strong>
          </p>
          <p>
            If you have any questions about these Terms or the Services, please
            contact QR at support@quantumred.io or by mail at 1900 Campus
            Commons Drive, Suite 100, Reston VA 20191.
          </p>
          <Legalese marginTop />
        </Col>
      </Row>
    </>
  );
};

export default Terms;

import React from 'react';
import logo from '../../assets/vlog.png';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => (
  <Navbar expand="lg" bg="light" data-bs-theme="light">
    <Navbar.Brand>
      <Image fluid src={logo} style={{ maxHeight: '24px' }} />
    </Navbar.Brand>
  </Navbar>
);

export default Header;

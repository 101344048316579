import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CheckSquare, Square } from 'react-bootstrap-icons';

export function Plans({ envJSON }) {
  const planCard = (
    title,
    price,
    description,
    buttonHRef,
    buttonLabel,
    buttonDisable = false,
    checked = false,
    isLast = false,
  ) => (
    <Row className={!isLast ? 'mb-5' : ''}>
      <Col xs={2} sm={1}>
        <div className={`checkbox ${checked}`}>
          {checked ? <CheckSquare size={'100%'} /> : <Square size={'100%'} />}
        </div>
      </Col>
      <Col xs={10} sm={9}>
        <div className="fs-5">{title}</div>
        <div className="fs-6 fw-bold">{price}</div>
        <div className="fs-6">
          <small>{description}</small>
        </div>
      </Col>
      <Col lg={2}>
        <Button className="w-100" disabled={buttonDisable} href={buttonHRef}>
          {buttonLabel}
        </Button>
      </Col>
    </Row>
  );

  return (
    <>
      {planCard(
        'Website Visitor Log (Basic Data)',
        '$249 set-up, $149/month & $0.25 per record',
        'Know who visited your website. Get a visitor’s Name, Email, Address, Phone Numbers, Location, Site Visits',
        `${envJSON.VLOG_URL}/subscribe?plan=1`,
        'Purchase Online',
      )}
      {planCard(
        'Website Visitor Log (Enhanced Data)',
        '$249 set-up, $149/month & $1 per record',
        'Know who visited your website: Get Basic Data plus wealth/income, political ideology, likelihood to donate, capacity to donate, and more',
        `${envJSON.VLOG_URL}/subscribe?plan=2`,
        'Coming Soon',
        true,
      )}
      {planCard(
        'DRE New Donor Discovery and Outreach Platform',
        'Starting at $50/mo & $1 per record',
        'Software platform to discover new hard-to-find, first-time, wealthy donors passionate about your cause',
        `mailto:${envJSON.POINT_OF_CONTACT}`,
        'Contact Us',
      )}
      {planCard(
        'Benjamin Platform',
        'Starting at $150/mo plus multiple tiers and additional services depending on your needs',
        'Get the big money. Large donor CRM and digital donor bundling management and outreach platform',
        `mailto:${envJSON.POINT_OF_CONTACT}`,
        'Contact Us',
      )}
      {planCard(
        'QuantumRed Consulting',
        'Starting at $1000',
        'We provide expertise to nonprofits and campaigns in AI, data science, software, and digital fundraising on a monthly retainer or outcome based pricing',
        `mailto:${envJSON.POINT_OF_CONTACT}`,
        'Contact Us',
        false,
        false,
        true,
      )}
    </>
  );
}
